
<template>
  <main>
    <div class="container mt-3 p-3 text-center">
      <h3>{{ $t("labels.privacyPolicy") }}</h3>
    </div>

    <div class="container shadow p-3 mb-5 bg-white rounded"  v-if="$i18n.locale=='en'">
      <p>Effective date: 2022-06-01</p>
      <p>1. <b>Introduction</b></p>
      <p>Welcome to <b> ThemyCV</b>. </p>
      <p>
        <b>ThemyCV</b> (“us”, “we”, or “our”) operates
        <b>https://themycv.com</b> (hereinafter referred to as
        <b>“Service”</b>).
      </p>
      <p>
        Our Privacy Policy governs your visit to <b>https://themycv.com</b>, and
        explains how we collect, safeguard and disclose information that results
        from your use of our Service.
      </p>
      <p>
        We use your data to provide and improve Service. By using Service, you
        agree to the collection and use of information in accordance with this
        policy. Unless otherwise defined in this Privacy Policy, the terms used
        in this Privacy Policy have the same meanings as in our Terms and
        Conditions.
      </p>
      <p>
        Our Terms and Conditions (<b>“Terms”</b>) govern all use of our Service
        and together with the Privacy Policy constitutes your agreement with us
        (<b>“agreement”</b>).
      </p>
      <p>2. <b>Definitions</b></p>
      <p>
        <b>SERVICE</b> means the https://themycv.com website operated by The my
        CV.
      </p>
      <p>
        <b>PERSONAL DATA</b> means data about a living individual who can be
        identified from those data (or from those and other information either
        in our possession or likely to come into our possession).
      </p>
      <p>
        <b>USAGE DATA</b> is data collected automatically either generated by
        the use of Service or from Service infrastructure itself (for example,
        the duration of a page visit).
      </p>
      <p>
        <b>COOKIES</b> are small files stored on your device (computer or mobile
        device).
      </p>
      <p>
        <b>DATA CONTROLLER</b> means a natural or legal person who (either alone
        or jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal data are, or are to be,
        processed. For the purpose of this Privacy Policy, we are a Data
        Controller of your data.
      </p>
      <p>
        <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal
        person who processes the data on behalf of the Data Controller. We may
        use the services of various Service Providers in order to process your
        data more effectively.
      </p>
      <p>
        <b>DATA SUBJECT</b> is any living individual who is the subject of
        Personal Data.
      </p>
      <p>
        <b>THE USER</b> is the individual using our Service. The User
        corresponds to the Data Subject, who is the subject of Personal Data.
      </p>
      <p>3. <b>Information Collection and Use</b></p>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <p>4. <b>Types of Data Collected</b></p>
      <p>
        <b>Personal Data</b>
      </p>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (<b>“Personal Data”</b>). Personally identifiable
        information may include, but is not limited to:
      </p>
      <p>0.1. Email address</p>
      <p>0.2. First name and last name</p>
      <p>0.3. Phone number</p>
      <p>0.4. Address, Country, State, Province, ZIP/Postal code, City</p>
      <p>0.5. Cookies and Usage Data</p>
      <p>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link.
      </p>
      <p>
        <b>Usage Data</b>
      </p>
      <p>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access Service by or through any device
        (<b>“Usage Data”</b>).
      </p>
      <p>
        This Usage Data may include information such as your computer’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When you access Service with a device, this Usage Data may include
        information such as the type of device you use, your device unique ID,
        the IP address of your device, your device operating system, the type of
        Internet browser you use, unique device identifiers and other diagnostic
        data.
      </p>
      <p>
        <b>Location Data</b>
      </p>
      <p>
        We may use and store information about your location if you give us
        permission to do so (<b>“Location Data”</b>). We use this data to
        provide features of our Service, to improve and customize our Service.
      </p>
      <p>
        You can enable or disable location services when you use our Service at
        any time by way of your device settings.
      </p>
      <p>
        <b>Tracking Cookies Data</b>
      </p>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </p>
      <p>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyze our Service.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>
      <p>Examples of Cookies we use:</p>
      <p>
        0.1. <b>Session Cookies:</b> We use Session Cookies to operate our
        Service.
      </p>
      <p>
        0.2. <b>Preference Cookies:</b> We use Preference Cookies to remember
        your preferences and various settings.
      </p>
      <p>
        0.3. <b>Security Cookies:</b> We use Security Cookies for security
        purposes.
      </p>
      <p>
        0.4. <b>Advertising Cookies:</b> Advertising Cookies are used to serve
        you with advertisements that may be relevant to you and your interests.
      </p>
      <p>
        <b>Other Data</b>
      </p>
      <p>
        While using our Service, we may also collect the following information:
        sex, age, date of birth, place of birth, passport details, citizenship,
        registration at place of residence and actual address, telephone number
        (work, mobile), details of documents on education, qualification,
        professional training, employment agreements,
         <a href="https://policymaker.io/non-disclosure-agreement/"
          >NDA agreements</a
        >, information on bonuses and compensation, information on marital
        status, family members, social security (or other taxpayer
        identification) number, office location and other data.
      </p>
      <p>5. <b>Use of Data</b></p>
      <p>ThemyCV uses the collected data for various purposes:</p>
      <p>0.1. to provide and maintain our Service;</p>
      <p>0.2. to notify you about changes to our Service;</p>
      <p>
        0.3. to allow you to participate in interactive features of our Service
        when you choose to do so;
      </p>
      <p>0.4. to provide customer support;</p>
      <p>
        0.5. to gather analysis or valuable information so that we can improve
        our Service;
      </p>
      <p>0.6. to monitor the usage of our Service;</p>
      <p>0.7. to detect, prevent and address technical issues;</p>
      <p>0.8. to fulfil any other purpose for which you provide it;</p>
      <p>
        0.9. to carry out our obligations and enforce our rights arising from
        any contracts entered into between you and us, including for billing and
        collection;
      </p>
      <p>
        0.10. to provide you with notices about your account and/or
        subscription, including expiration and renewal notices,
        email-instructions, etc.;
      </p>
      <p>
        0.11. to provide you with news, special offers and general information
        about other goods, services and events which we offer that are similar
        to those that you have already purchased or enquired about unless you
        have opted not to receive such information;
      </p>
      <p>
        0.12. in any other way we may describe when you provide the information;
      </p>
      <p>0.13. for any other purpose with your consent. </p>
      <p>6. <b>Retention of Data</b></p>
      <p>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </p>
      <p>
        We will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period, except when this data
        is used to strengthen the security or to improve the functionality of
        our Service, or we are legally obligated to retain this data for longer
        time periods.
      </p>
      <p>7. <b>Transfer of Data</b></p>
      <p>
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>
      <p>
        If you are located outside EUA and choose to provide information to us,
        please note that we transfer the data, including Personal Data, to EUA
        and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        ThemyCV will take all the steps reasonably necessary to ensure that your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of your Personal Data will take place to an organisation or
        a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <p>8. <b>Disclosure of Data</b></p>
      <p>
        We may disclose personal information that we collect, or you provide:
      </p>
      <p>0.1. <b>Disclosure for Law Enforcement. </b></p>
      <p>
        Under certain circumstances, we may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities.
      </p>
      <p>0.2. <b>Business Transaction. </b></p>
      <p>
        If we or our subsidiaries are involved in a merger, acquisition or asset
        sale, your Personal Data may be transferred.
      </p>
      <p>0.3. <b>Other cases. We may disclose your information also:</b></p>
      <p>0.3.1. to our subsidiaries and affiliates;</p>
      <p>
        0.3.2. to contractors, service providers, and other third parties we use
        to support our business;
      </p>
      <p>0.3.3. to fulfill the purpose for which you provide it;</p>
      <p>
        0.3.4. for the purpose of including your company’s logo on our website;
      </p>
      <p>
        0.3.5. for any other purpose disclosed by us when you provide the
        information;
      </p>
      <p>0.3.6. with your consent in any other cases;</p>
      <p>
        0.3.7. if we believe disclosure is necessary or appropriate to protect
        the rights, property, or safety of the Company, our customers, or
        others.
      </p>
      <p>9. <b>Security of Data</b></p>
      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>
      <p>
        10.
        <b
          >Your Data Protection Rights Under General Data Protection Regulation
          (GDPR)
        </b>
      </p>
      <p>
        If you are a resident of the European Union (EU) and European Economic
        Area (EEA), you have certain data protection rights, covered by GDPR.
      </p>
      <p>
        We aim to take reasonable steps to allow you to correct, amend, delete,
        or limit the use of your Personal Data.
      </p>
      <p>
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please email us at
        <b>themycvbusiness@gmail.com</b>.
      </p>
      <p>
        In certain circumstances, you have the following data protection rights:
      </p>
      <p>
        0.1. the right to access, update or to delete the information we have on
        you;
      </p>
      <p>
        0.2. the right of rectification. You have the right to have your
        information rectified if that information is inaccurate or incomplete;
      </p>
      <p>
        0.3. the right to object. You have the right to object to our processing
        of your Personal Data;
      </p>
      <p>
        0.4. the right of restriction. You have the right to request that we
        restrict the processing of your personal information;
      </p>
      <p>
        0.5. the right to data portability. You have the right to be provided
        with a copy of your Personal Data in a structured, machine-readable and
        commonly used format;
      </p>
      <p>
        0.6. the right to withdraw consent. You also have the right to withdraw
        your consent at any time where we rely on your consent to process your
        personal information;
      </p>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests. Please note, we may not able to provide
        Service without some necessary data.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic
        Area (EEA).
      </p>
      <p>
        11.
        <b
          >Your Data Protection Rights under the California Privacy Protection
          Act (CalOPPA)</b
        >
      </p>
      <p>
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law’s reach
        stretches well beyond California to require a person or company in the
        United States (and conceivable the world) that operates websites
        collecting personally identifiable information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals with whom it is being
        shared, and to comply with this policy.
      </p>
      <p>According to CalOPPA we agree to the following:</p>
      <p>0.1. users can visit our site anonymously;</p>
      <p>
        0.2. our Privacy Policy link includes the word “Privacy”, and can easily
        be found on the home page of our website;
      </p>
      <p>
        0.3. users will be notified of any privacy policy changes on our Privacy
        Policy Page;
      </p>
      <p>
        0.4. users are able to change their personal information by emailing us
        at <b>themycvbusiness@gmail.com</b>.
      </p>
      <p>Our Policy on “Do Not Track” Signals:</p>
      <p>
        We honor Do Not Track signals and do not track, plant cookies, or use
        advertising when a Do Not Track browser mechanism is in place. Do Not
        Track is a preference you can set in your web browser to inform websites
        that you do not want to be tracked.
      </p>
      <p>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </p>
      <p>
        12.
        <b
          >Your Data Protection Rights under the California Consumer Privacy Act
          (CCPA)</b
        >
      </p>
      <p>
        If you are a California resident, you are entitled to learn what data we
        collect about you, ask to delete your data and not to sell (share) it.
        To exercise your data protection rights, you can make certain requests
        and ask us:
      </p>
      <p>
        <b
          >0.1. What personal information we have about you. If you make this
          request, we will return to you:</b
        >
      </p>
      <p>
        0.0.1. The categories of personal information we have collected about
        you.
      </p>
      <p>
        0.0.2. The categories of sources from which we collect your personal
        information.
      </p>
      <p>
        0.0.3. The business or commercial purpose for collecting or selling your
        personal information.
      </p>
      <p>
        0.0.4. The categories of third parties with whom we share personal
        information.
      </p>
      <p>
        0.0.5. The specific pieces of personal information we have collected
        about you.
      </p>
      <p>
        0.0.6. A list of categories of personal information that we have sold,
        along with the category of any other company we sold it to. If we have
        not sold your personal information, we will inform you of that fact.
      </p>
      <p>
        0.0.7. A list of categories of personal information that we have
        disclosed for a business purpose, along with the category of any other
        company we shared it with.
      </p>
      <p>
        Please note, you are entitled to ask us to provide you with this
        information up to two times in a rolling twelve-month period. When you
        make this request, the information provided may be limited to the
        personal information we collected about you in the previous 12 months.
      </p>
      <p>
        <b
          >0.2. To delete your personal information. If you make this request,
          we will delete the personal information we hold about you as of the
          date of your request from our records and direct any service providers
          to do the same. In some cases, deletion may be accomplished through
          de-identification of the information. If you choose to delete your
          personal information, you may not be able to use certain functions
          that require your personal information to operate. </b
        >
      </p>
      <p>
        <b
          >0.3. To stop selling your personal information. We don’t sell or rent
          your personal information to any third parties for any purpose. We do
          not sell your personal information for monetary consideration.
          However, under some circumstances, a transfer of personal information
          to a third party, or within our family of companies, without monetary
          consideration may be considered a “sale” under California law. You are
          the only owner of your Personal Data and can request disclosure or
          deletion at any time. </b
        >
      </p>
      <p>
        If you submit a request to stop selling your personal information, we
        will stop making such transfers.
      </p>
      <p>
        Please note, if you ask us to delete or stop selling your data, it may
        impact your experience with us, and you may not be able to participate
        in certain programs or membership services which require the usage of
        your personal information to function. But in no circumstances, we will
        discriminate against you for exercising your rights.
      </p>
      <p>
        To exercise your California data protection rights described above,
        please send your request(s) by email: <b>themycvbusiness@gmail.com</b>.
      </p>
      <p>
        Your data protection rights, described above, are covered by the CCPA,
        short for the California Consumer Privacy Act. To find out more, visit
        the official California Legislative Information website. The CCPA took
        effect on 01/01/2020.
      </p>
      <p>13. <b>Service Providers</b></p>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service (<b>“Service Providers”</b>), provide Service on our behalf,
        perform Service-related services or assist us in analysing how our
        Service is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <p>14. <b>Analytics</b></p>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      <p>15. <b>CI/CD tools</b></p>
      <p>
        We may use third-party Service Providers to automate the development
        process of our Service.
      </p>
      <p>16. <b>Advertising</b></p>
      <p>
        We may use third-party Service Providers to show advertisements to you
        to help support and maintain our Service.
      </p>
      <p>17. <b>Behavioral Remarketing</b></p>
      <p>
        We may use remarketing services to advertise on third party websites to
        you after you visited our Service. We and our third-party vendors use
        cookies to inform, optimise and serve ads based on your past visits to
        our Service.
      </p>
      <p>18. <b>Payments</b></p>
      <p>
        We may provide paid products and/or services within Service. In that
        case, we use third-party services for payment processing (e.g. payment
        processors).
      </p>
      <p>
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p>19. <b>Links to Other Sites</b></p>
      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party’s site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <!--
      <p>
        For example, the outlined
         <a href="https://policymaker.io/privacy-policy/">privacy policy</a> has
        been made using  <a href="https://policymaker.io/">PolicyMaker.io</a>, a
        free tool that helps create high-quality legal documents. PolicyMaker’s
         <a href="https://policymaker.io/privacy-policy/"
          >privacy policy generator</a
        >
        is an easy-to-use tool for creating a
         <a href="https://policymaker.io/blog-privacy-policy/"
          >privacy policy for blog</a
        >, website, e-commerce store or mobile app.
      </p>
      -->
      <p>
        20.
        <b>
          <b>Children’s Privacy</b>
        </b>
      </p>
      <p>
        Our Services are not intended for use by children under the age of 18
        (<b>“Child”</b> or <b>“Children”</b>).
      </p>
      <p>
        We do not knowingly collect personally identifiable information from
        Children under 18. If you become aware that a Child has provided us with
        Personal Data, please contact us. If we become aware that we have
        collected Personal Data from Children without verification of parental
        consent, we take steps to remove that information from our servers.
      </p>
      <p>21. <b>Changes to This Privacy Policy</b></p>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update “effective date” at
        the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <p>22. <b>Contact Us</b></p>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email: <b>themycvbusiness@gmail.com</b>.
      </p>
      <!--
      <p style="margin-top: 5em; font-size: 0.7em">
        This
         <a href="https://policymaker.io/privacy-policy/">Privacy Policy</a> was
        created for <b>https://themycv.com</b> by
         <a href="https://policymaker.io">PolicyMaker.io</a> on 2022-06-01.
      </p>
      -->
    </div>

    <!-- ################# ################### -->
    <div class="container shadow p-3 mb-5 bg-white rounded"  v-else>
      <p>Data efetiva: 2022-06-01</p>
      <p>1. <b>Introdução</b></p>
      <p>Bem-vindo ao <b> ThemyCV</b>. </p>
      <p>
        <b>ThemyCV</b> (“nós”, "nós" ou "nosso") opera <b
          >https://themycv.com</b>
        (doravante referido como <b>"Serviço"</b>).
      </p>
      <p>
        Nossa Privacidade A política rege sua visita a
        <b>https://themycv.com</b> e explica como coletamos, protegemos e
        divulgamos informações resultantes do uso do nosso Serviço.
      </p>
      <p>
        Usamos seus dados para fornecer e melhorar o Serviço. Ao usar o Serviço,
        você concorda com a coleta e uso de informações de acordo com esta
        política. A menos que definido de outra forma nesta Política de
        Privacidade, os termos usados nesta Política de Privacidade têm os
        mesmos significados que em nossos Termos e Condições.
      </p>
      <p>
        Nossos Termos e Condições (<b>“Termos”</b>) regem todo o uso de nosso
        Serviço e, juntamente com a Política de Privacidade, constituem seu
        acordo conosco(<b>“contrato”</b>).
      </p>
      <p>2. <b>Definições</b></p>
      <p>
        <b>SERVIÇO</b> significa o site https://themycv.com operado pelo ThemyCV.
      </p>
      <p>
        <b>DADOS PESSOAIS </b> significa dados sobre um indivíduo vivo que pode
        ser identificado a partir desses dados (ou dessas e outras informações
        que estejam em nossa posse ou que possam vir a estar em nossa posse).
      </p>
     
      <p>
        <b>DADOS DE USO</b> são dados coletados automaticamente gerados pelo
        uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a
        duração de uma visita à página).
      </p>
      <p>
        <b>COOKIES</b> são pequenos arquivos armazenados em seu dispositivo
        (computador ou dispositivo móvel).
      </p>
      <p>
        <b>CONTROLADOR DE DADOS</b> significa uma pessoa física ou jurídica que
        (sozinho ou em conjunto ou em comum com outras pessoas) determina os
        fins para os quais e a maneira pela qual qualquer os dados pessoais são
        ou serão processados. Para os fins desta Política de Privacidade, somos
        um Controlador de Dados de seus dados.
      </p>
      <p>
        <b>PROCESSADORES DE DADOS (OU PRESTADORES DE SERVIÇOS)</b> significa
        qualquer pessoa física ou jurídica que processe os dados em nome do
        Controlador de dados. Podemos usar os serviços de vários Provedores de
        Serviços para processar seus dados de forma mais eficaz.
      </p>
      <p>
        <b>SUJEITO DE DADOS</b> é qualquer indivíduo vivo que seja objeto de
        Dados Pessoais.
      </p>
      <p>
        <b>O USUÁRIO</b> é o indivíduo que usa nosso Serviço. O Usuário
        corresponde ao Titular dos Dados, que é o Titular dos Dados Pessoais.
      </p>
      <p>3. <b>Coleta e uso de informações</b></p>
      <p>
        Coletamos vários tipos diferentes de informações para vários propósitos
        para fornecer e melhorar nosso serviço para você.
      </p>
      <p>4. <b>Tipos de dados coletados</b></p>
      <p>
        <b>Dados pessoais</b>
      </p>
      <p>
        Ao usar nosso Serviço, podemos solicitar que você nos forneça
        determinadas informações de identificação pessoal que podem ser usados
        para contatá-lo ou identificá-lo (<b>“Dados Pessoais”</b>). As
        informações de identificação pessoal podem incluir, mas não se limitam
        a:
      </p>
      <p>0.1. Endereço de e-mail</p>
      <p>0.2. Nome e sobrenome</p>
      <p>0.3. Número de telefone</p>
      <p>0.4. Endereço, País, Estado, Província, CEP/Código Postal, Cidade</p>
      <p>0.5. Cookies e dados de uso</p>
      <p>
        Podemos usar seus Dados Pessoais para contatá-lo com boletins
        informativos, materiais de marketing ou promocionais e outras
        informações que possam ser de seu interesse. Você pode optar por não
        receber um ou todos esses nossas comunicações seguindo o link de
        cancelamento de inscrição.
      </p>
      <p>
        <b>Dados de uso</b>
      </p>
      <p>
        Também podemos coletar informações que seu navegador envia sempre que
        você visita nosso Serviço ou quando você acessa o Serviço por ou através
        de qualquer dispositivo (<b>“Dados de Uso”</b>).
      </p>
      <p>
        Estes Dados de Uso podem incluir informações como o endereço do
        protocolo de Internet do seu computador (por exemplo, endereço IP), tipo
        de navegador, versão do navegador, páginas de nosso Serviço que você
        visita, a hora e a data de sua visita, o tempo gasto nessas páginas,
        identificadores exclusivos de dispositivo e outros dados de diagnóstico.
      </p>
      <p>
        Quando você acessa o Serviço com um dispositivo, esses Dados de Uso
        podem incluir informações como como o tipo de dispositivo que você usa,
        o ID exclusivo do seu dispositivo, o endereço IP do seu dispositivo, o
        sistema operacional do seu dispositivo, o tipo de Internet navegador que
        você usa, identificadores exclusivos de dispositivo e outros dados de
        diagnóstico.
      </p>
      <p>
        <b>Dados de localização</b>
      </p>
      <p>
        Podemos usar e armazenar informações sobre sua localização se você nos
        der permissão para isso (<b>“Dados de localização”</b>). Usamos esses
        dados para fornecer recursos de nosso Serviço, para melhorar e
        personalizar nosso Serviço.
      </p>
      <p>
        Você pode ativar ou desativar os serviços de localização ao usar nosso
        Serviço a qualquer momento por meio das configurações do seu
        dispositivo.
      </p>
      <p>
        <b>Dados de cookies de rastreamento</b>
      </p>
      <p>
        Usamos cookies e tecnologias de rastreamento semelhantes para rastrear a
        atividade em nosso Serviço e mantemos certas informações.
      </p>
      <p>
        Cookies são arquivos com um pequena quantidade de dados que pode incluir
        um identificador único anônimo. Os cookies são enviados para o seu
        navegador a partir de um site e armazenados no seu dispositivo. Outras
        tecnologias de rastreamento também são usadas, como beacons, tags e
        scripts, para coletar e rastrear informações e melhorar e analisar nosso
        Serviço.
      </p>
      <p>
        Você pode instruir seu navegador a recusar todos os cookies ou indicar
        quando um cookie está sendo enviado. No entanto, se você não aceitar
        cookies, talvez não consiga usar algumas partes do nosso Serviço.
      </p>
      <p>Exemplos de cookies que usamos:</p>
      <p>
        0.1. <b>Cookies de Sessão:</b> Usamos Cookies de Sessão para operar
        nosso Serviço.
      </p>
      <p>
        0.2. <b>Cookies de Preferência:</b> Usamos Cookies de Preferência para
        lembrar suas preferências e várias configurações.
      </p>
      <p>
        0.3. <b>Cookies de Segurança:</b> Usamos Cookies de Segurança para fins
        de segurança.
      </p>
      <p>
        0.4. <b>Cookies de publicidade:</b> Cookies de publicidade são usados
        para fornecer anúncios que podem ser relevantes para você e seus
        interesses.
      </p>
      <p>
        <b>Outros dados</b>
      </p>
      <p>
        Ao usar nosso Serviço, também podemos coletar as seguintes informações:
        sexo, idade, data de nascimento, local de nascimento, detalhes do
        passaporte, cidadania, registro no local de residência e endereço real,
        número de telefone (trabalho, celular), detalhes de documentos sobre
        educação, qualificação, treinamento profissional, contratos de
        trabalho,  <a href="https://policymaker.io/non-disclosure-agreement/"
          >contratos de NDA</a
        >, informações sobre bônus e remuneração, informações sobre estado civil
        ,familiares, CPF (ou outro CPF), localização do escritório e outros
        dados.
      </p>
      <p>5. <b>Uso de dados</b></p>
      <p>ThemyCV usa os dados coletados para vários fins:</p>
      <p>0.1. para fornecer e manter nosso Serviço;</p>
      <p>0.2. para notificá-lo sobre alterações em nosso Serviço;</p>
      <p>
        0.3. para permitir que você participe de recursos interativos de nosso
        Serviço quando você optar por fazê-lo;
      </p>
      <p>0.4. para fornecer suporte ao cliente;</p>
      <p>
        0.5. coletar análises ou informações valiosas para que possamos melhorar
        nosso Serviço;
      </p>
      <p>0.6. para monitorar o uso do nosso Serviço;</p>
      <p>0.7. detectar, prevenir e resolver problemas técnicos;</p>
      <p>
        0.8. para cumprir qualquer outro propósito para o qual você o forneça;
      </p>
      <p>
        0.9. cumprir nossas obrigações e fazer valer nossos direitos decorrentes
        de quaisquer contratos celebrados entre você e nós, inclusive para
        cobrança e cobrança;
      </p>
      <p>
        0.10. para fornecer avisos sobre sua conta e/ou assinatura, incluindo
        avisos de expiração e renovação, instruções por e-mail etc.;
      </p>
      <p>
        0.11. para lhe fornecer notícias, ofertas especiais e informações gerais
        sobre outros bens, serviços e eventos que oferecemos que sejam
        semelhantes aos que você já comprou ou perguntou, a menos que tenha
        optado por não receber tais informações;
      </p>
      <p>
        0.12. de qualquer outra forma que possamos descrever quando você
        fornecer as informações;
      </p>
      <p>0.13. para qualquer outra finalidade com o seu consentimento. </p>
      <p>6. <b>Retenção de Dados</b></p>
      <p>
        Reteremos seus Dados Pessoais apenas pelo tempo necessário para os
        propósitos estabelecidos nesta Política de Privacidade. Reteremos e
        usaremos seus Dados Pessoais na medida necessária para cumprir nossas
        obrigações legais (por exemplo, se formos obrigados a reter seus dados
        para cumprir as leis aplicáveis), resolver disputas e fazer cumprir
        nossos acordos e políticas legais.
      </p>
      <p>
        Também reteremos os Dados de Uso para fins de análise interna. Os Dados
        de Uso geralmente são retidos por um período mais curto, exceto quando
        esses dados são usados para fortalecer a segurança ou melhorar a
        funcionalidade de nosso Serviço, ou somos legalmente obrigados a reter
        esses dados por períodos mais longos.
      </p>
      <p>7. <b>Transferência de Dados</b></p>
      <p>
        Suas informações, incluindo Dados Pessoais, podem ser transferidas para
        – e mantidas em – computadores localizados fora de seu estado,
        província, país ou outra jurisdição governamental onde as leis de
        proteção de dados podem diferir daqueles de sua jurisdição.
      </p>
      <p>
        Se você estiver localizado fora dos EUA e optar por nos fornecer
        informações, observe que transferimos os dados, incluindo Dados
        Pessoais, para os EUA e os processamos lá.
      </p>
      <p>
        Seu consentimento para esta Política de Privacidade seguido pelo envio
        de tais informações representa sua concordância com essa transferência.
      </p>
      <p>
        ThemyCV tomará todas as medidas razoavelmente necessárias para garantir
        que seus dados sejam tratados com segurança e de acordo com esta
        Privacidade Política e nenhuma transferência de seus dados pessoais
        ocorrerá para uma organização ou país, a menos que haja controles
        adequados em vigor, incluindo a segurança de seus dados e outras
        informações pessoais.
      </p>
      <p>8. <b>Divulgação de dados</b></p>
      <p>
        Podemos divulgar informações pessoais que coletamos ou você fornece:
      </p>
      <p>0.1. <b>Divulgação para aplicação da lei. </b></p>
      <p>
        Em certas circunstâncias, podemos ser obrigados a divulgar seus Dados
        Pessoais se exigido por lei ou em resposta a solicitações válidas de
        autoridades públicas.
      </p>
      <p>0.2. <b>Transação Comercial. </b></p>
      <p>
        Se nós ou nossas subsidiárias estivermos envolvidos em uma fusão,
        aquisição ou venda de ativos, seus Dados Pessoais poderão ser
        transferidos.
      </p>
      <p>0.3. <b>Outros casos. Também podemos divulgar suas informações:</b></p>
      <p>0.3.1. para nossas subsidiárias e afiliadas;</p>
      <p>
        0.3.2. para contratados, prestadores de serviços e outros terceiros que
        usamos para apoiar nossos negócios;
      </p>
      <p>0.3.3. para cumprir a finalidade para a qual você o fornece;</p>
      <p>
        0.3.4. com o objetivo de incluir o logotipo de sua empresa em nosso
        site;
      </p>
      <p>
        0.3.5. para qualquer outra finalidade divulgada por nós quando você
        fornecer as informações;
      </p>
      <p>0.3.6. com o seu consentimento em quaisquer outros casos;</p>
      <p>
        0.3.7. se acreditarmos que a divulgação é necessária ou apropriada para
        proteger os direitos, propriedade ou segurança da Empresa, nossos
        clientes ou outros.
      </p>
      <p>9. <b>Segurança dos Dados</b></p>
      <p>
        A segurança dos seus dados é importante para nós, mas lembre-se que
        nenhum método de transmissão pela Internet ou método de armazenamento
        eletrônico é 100% seguro. Embora nos esforcemos para usar meios
        comercialmente aceitáveis para proteger seus Dados Pessoais, não podemos
        garantir sua segurança absoluta.
      </p>
      <p>
        10. <b
          >Seus direitos de proteção de dados sob o Regulamento Geral de
          Proteção de Dados (GDPR)</b
        >
      </p>
      <p>
        Se você é residente da União Europeia (UE) e do Espaço Econômico Europeu
        (EEE), você tem certos direitos de proteção de dados, cobertos pelo
        GDPR.
      </p>
      <p>
        Nosso objetivo é tomar medidas razoáveis para permitir que você corrija,
        altere, exclua ou limite o uso de seus Dados Pessoais.
      </p>
      <p>
        Se você deseja ser informado sobre quais Dados Pessoais mantemos sobre
        você e se você deseja que eles sejam removidos de nossos sistemas, envie
        um e-mail em <b>themycvbusiness@gmail.com</b>.
      </p>
      <p>
        Em determinadas circunstâncias, você tem os seguintes direitos de
        proteção de dados:
      </p>
      <p>
        0.1. o direito de acessar, atualizar ou excluir as informações que temos
        sobre você;
      </p>
      <p>
        0.2. o direito de retificação. Você tem o direito de ter suas
        informações corrigidas se essas informações estiverem imprecisas ou
        incompletas;
      </p>
      <p>
        0.3. o direito de oposição. Você tem o direito de se opor ao nosso
        processamento de seus Dados Pessoais;
      </p>
      <p>
        0.4. o direito de restrição. Você tem o direito de solicitar que seja
        restrito o processamento de suas informações pessoais;
      </p>
      <p>
        0.5. o direito à portabilidade dos dados. Você tem o direito de receber
        uma cópia de seus Dados Pessoais em um formato estruturado, legível por
        máquina e comumente usado;
      </p>
      <p>
        0.6. o direito de retirar o consentimento. Você também tem o direito de
        retirar seu consentimento a qualquer momento em que confiarmos em seu
        consentimento para processar suas informações pessoais;
      </p>
      <p>
        Observe que podemos solicitar que você verifique sua identidade antes de
        responder a tais solicitações. Observe que talvez não possamos fornecer
        o Serviço sem alguns dados necessários.
      </p>
      <p>
        Você tem o direito de reclamar com uma Autoridade de Proteção de Dados
        sobre nossa coleta e uso de seus Dados Pessoais. Para obter mais
        informações, entre em contato com a autoridade local de proteção de
        dados no Espaço Econômico Europeu (EEE).
      </p>
      <p>
        11. <b
          >Seus direitos de proteção de dados sob a Lei de Proteção de
          Privacidade da Califórnia (CalOPPA)</b
        >
      </p>
      <p>
        CalOPPA é a primeira lei estadual do país a exigir que sites comerciais
        e serviços online publiquem uma política de privacidade. O alcance da
        lei vai muito além da Califórnia para exigir que uma pessoa ou empresa
        nos Estados Unidos (e possivelmente no mundo) que opere sites que
        coletam informações de identificação pessoal de consumidores da
        Califórnia publique uma política de privacidade visível em seu site
        informando exatamente as informações coletadas e os indivíduos com os
        quais é sendo compartilhado e para cumprir com esta política.
      </p>
      <p>De acordo com CalOPPA, concordamos com o seguinte:</p>
      <p>0.1. os usuários podem visitar nosso site anonimamente;</p>
      <p>
        0.2. nosso link de Política de Privacidade inclui a palavra
        “Privacidade” e pode ser facilmente encontrado na página inicial do
        nosso site;
      </p>
      <p>
        0.3. os usuários serão notificados de quaisquer alterações na política
        de privacidade em nossa página PrivacyPolicy;
      </p>
      <p>
        0.4. os usuários podem alterar suas informações pessoais enviando-nos um
        e-mail para<b>themycvbusiness@gmail.com</b>.
      </p>
      <p>Nossa política sobre sinais “Não rastrear”:</p>
      <p>
        Nós honre os sinais Do Not Track e não rastreie, plante cookies ou use
        publicidade quando um mecanismo de navegador Do Not Track estiver em
        vigor. Não rastrear é uma preferência que você pode definir em seu
        navegador da web para informar sites que você não deseja ser rastreado.
      </p>
      <p>
        Você pode ativar ou desativar o Não rastrear visitando a página de
        preferências ou configurações do seu navegador da web.
      </p>
      <p>
        12. <b
          >Seus direitos de proteção de dados sob a Lei de Privacidade do
          Consumidor da Califórnia (CCPA)</b
        >
      </p>
      <p>
        Se você é residente da Califórnia, tem o direito de saber quais dados
        coletamos sobre você, peça para excluir seus dados e não para vendê-los
        (compartilhar). Para exercer seus direitos de proteção de dados, você
        pode fazer algumas solicitações e nos perguntar:
      </p>
      <p>
        <b
          >0.1. Quais informações pessoais temos sobre você. Se você fizer essa
          solicitação, retornaremos para você:</b
        >
      </p>
      <p>
        0.0.1. As categorias de informações pessoais que coletamos sobre você.
      </p>
      <p>
        0.0.2. As categorias de fontes das quais coletamos suas informações
        pessoais.
      </p>
      <p>
        0.0.3. A finalidade comercial ou comercial para coletar ou vender suas
        informações pessoais.
      </p>
      <p>
        0.0.4. As categorias de terceiros com quem compartilhamos informações
        pessoais.
      </p>
      <p>
        0.0.5. As informações pessoais específicas que coletamos sobre você.
      </p>
      <p>
        0.0.6. Uma lista de categorias de informações pessoais que vendemos,
        juntamente com a categoria de qualquer outra empresa para a qual as
        vendemos. Se não vendermos suas informações pessoais, informaremos esse
        fato.
      </p>
      <p>
        0.0.7. Uma lista de categorias de informações pessoais que divulgamos
        para fins comerciais, juntamente com a categoria de qualquer outra
        empresa com a qual as compartilhamos.
      </p>
      <p>
        Observe que você tem o direito de solicitar que forneçamos essas
        informações até duas vezes em um período de doze meses consecutivos.
        Quando você faz essa solicitação, as informações fornecidas podem ser
        limitadas às informações pessoais que coletamos sobre você nos últimos
        12 meses.
      </p>
      <p>
        <b
          >0.2. Para excluir suas informações pessoais. Se você fizer essa
          solicitação, excluiremos as informações pessoais que mantemos sobre
          você a partir da data de sua solicitação de nossos registros e
          instruiremos quaisquer provedores de serviços a fazer o mesmo. Em
          alguns casos, a exclusão pode ser realizada por meio da
          desidentificação das informações. Se você optar por excluir suas
          informações pessoais, talvez não consiga usar certas funções que
          exigem que suas informações pessoais funcionem. </b
        >
      </p>
      <p>
        <b
          >0.3. Parar de vender suas informações pessoais. Não vendemos ou
          alugamos suas informações pessoais a terceiros para qualquer
          finalidade. Não vendemos suas informações pessoais por consideração
          monetária. No entanto, em algumas circunstâncias, uma transferência de
          informações pessoais para terceiros ou dentro de nossa família de
          empresas, sem consideração monetária, pode ser considerada uma “venda”
          de acordo com a lei da Califórnia. Você é o único proprietário de seus
          Dados Pessoais e pode solicitar a divulgação ou exclusão a qualquer
          momento. </b
        >
      </p>
      <p>
        Se você enviar uma solicitação para interromper a venda de suas
        informações pessoais, deixaremos de fazer tais transferências.
      </p>
      <p>
        Observe que, se você nos pedir para excluir ou parar de vender seus
        dados, isso pode afetar sua experiência conosco e você pode não
        conseguir participar de determinados programas ou serviços de associação
        que exigem o uso de suas informações pessoais para funcionar. Mas, em
        nenhuma circunstância, discriminaremos você por exercer seus direitos.
      </p>
      <p>
        Para exercer seus direitos de proteção de dados da Califórnia descritos
        acima, envie sua solicitação por e-mail:
        <b>themycvbusiness@gmail.com</b>.
      </p>
      <p>
        Seus direitos de proteção de dados, descritos acima, são cobertos pela
        CCPA, abreviação de Lei de Privacidade do Consumidor da Califórnia. Para
        saber mais, visite o site oficial de Informações Legislativas da
        Califórnia. A CCPA entrou em vigor em 01/01/2020.
      </p>
      <p>13. <b>Provedores de Serviços</b></p>
      <p>
        Podemos empregar empresas e indivíduos terceirizados para facilitar
        nosso Serviço (<b>“Provedores de Serviços”</b>), fornecer Serviços em
        nosso nome, realizar serviços relacionados serviços ou nos ajudar a
        analisar como nosso Serviço é usado.
      </p>
      <p>
        Esses terceiros têm acesso aos seus Dados Pessoais apenas para realizar
        essas tarefas em nosso nome e são obrigados a não divulgá-los ou usá-los
        para qualquer outra finalidade.
      </p>
      
      <p>14. <b>Analytics</b></p>
      <p>
        Podemos usar provedores de serviços terceirizados para monitorar e
        analisar o uso de nosso serviço.
      </p>
      <p>15. <b>Ferramentas de CI/CD</b></p>
      <p>
        Podemos usar provedores de serviços terceirizados para automatizar o
        processo de desenvolvimento de nosso serviço.
      </p>
      <p>16. <b>Publicidade</b></p>
      <p>
        Podemos usar Provedores de Serviços terceirizados para mostrar anúncios
        a você para ajudar a dar suporte e manter nosso Serviço.
      </p>
      <p>17. <b>Remarketing Comportamental</b></p>
      <p>
        Podemos usar serviços de remarketing para anunciar em sites de terceiros
        para você depois que você visitou nosso Serviço. Nós e nossos
        fornecedores terceirizados usamos cookies para informar, otimizar e
        veicular anúncios com base em suas visitas anteriores ao nosso Serviço.
      </p>
      <p>18. <b>Pagamentos</b></p>
      <p>
        Podemos fornecer produtos e/ou serviços pagos dentro do Serviço. Nesse
        caso, usamos serviços de terceiros para processamento de pagamentos (por
        exemplo, processadores de pagamento).
      </p>
      <p>
        Não armazenaremos ou coletaremos os detalhes do seu cartão de pagamento.
        Essas informações são fornecidas diretamente aos nossos processadores de
        pagamento terceirizados, cujo uso de suas informações pessoais é regido
        por sua Política de Privacidade. Esses processadores de pagamento seguem
        os padrões estabelecidos pelo PCI-DSS, gerenciados pelo PCISecurity
        Standards Council, que é um esforço conjunto de marcas como Visa,
        Mastercard, American Express e Discover. Os requisitos do PCI-DSS ajudam
        a garantir o manuseio seguro das informações de pagamento.
      </p>
      <p>19. <b>Links para outros sites</b></p>
      <p>
        Nosso Serviço pode conter links para outros sites que não são operados
        por nós. Se você clicar em um link de terceiros, será direcionado ao
        site desse terceiro. Recomendamos que você revise a Política de
        Privacidade de cada site que visitar.
      </p>
      <p>
        Não temos controle e não assumimos responsabilidade pelo conteúdo,
        políticas de privacidade ou práticas de quaisquer sites ou serviços de
        terceiros.
      </p>
       <!--
      <p>
        Por exemplo, a <a href="https://policymaker.io/privacy-policy/"
          >política de privacidade</a
        >
        descrita foi feita usando
         <a href="https://policymaker.io/">PolicyMaker.io</a>, uma ferramenta
        gratuita que ajuda a criar documentos jurídicos de alta qualidade. O <a
          href="https://policymaker.io/privacy-policy/"
          >gerador de política de privacidade</a
        >
        do PolicyMaker é uma ferramenta fácil de usar para criar um <a
          href="https://policymaker.io /blog-privacy-policy/"
          >política de privacidade para blog</a
        >, site, loja de comércio eletrônico ou aplicativo móvel.
      </p>
      -->
      <p>
        20.
        <b>
          <b>Privacidade das Crianças</b>
        </b>
      </p>
      <p>
        Nossos Serviços não se destinam ao uso por crianças menores de 18 anos
        (<b>“Criança”</b>ou <b>“Crianças”</b>).
      </p>
      <p>
        Nós não coletamos intencionalmente informações de identificação pessoal
        de Crianças menores de 18 anos. Se você souber que uma Criança nos
        forneceu Dados Pessoais, entre em contato conosco. Se tomarmos
        conhecimento de que coletamos Dados Pessoais de Crianças sem a
        verificação do consentimento dos pais, tomaremos medidas para remover
        essas informações de nossos servidores.
      </p>
      <p>21. <b>Alterações nesta Política de Privacidade</b></p>
      <p>
        Podemos atualizar nossa Política de Privacidade de tempos em tempos.
        Notificaremos você sobre quaisquer alterações publicando a nova Política
        de Privacidade nesta página.
      </p>
      <p>
        Informaremos você por e-mail e/ou um aviso em destaque em nosso Serviço,
        antes que a alteração entre em vigor e atualize “em  data efetiva” no topo
        desta Política de Privacidade.
      </p>
      <p>
        Você é aconselhado a revisar esta Política de Privacidade periodicamente
        para quaisquer alterações. As alterações nesta Política de Privacidade
        são efetivas quando publicadas nesta página.
      </p>
      <p>22. <b>Fale Conosco</b></p>
      <p>
        Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em
        contato conosco pelo e-mail: <b>themycvbusiness@gmail.com</b>.
      </p>
      <!--
      <p style="margin-top: 5em; font-size: 0.7em">
        Esta <a href="https://policymaker.io/privacy-policy/"
          >Política de Privacidade</a
        >
        foi criada para <b> https://themycv.com</b> por <a
          href="https://policymaker.io"
          >PolicyMaker.io</a
        >
        em 01/06/2022.
      </p>
      -->
    </div>
  </main>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
</style>
